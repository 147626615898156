.base {
    border-bottom: 1px solid #BEBED2;
}

.base.disabled {
    opacity: 0.5;
}

.base.disabled header {
    cursor: default;
}

.base header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    transition: 200ms;
}

.base header:hover {
    background-color: #1f2d6a0d;
}

.base header img {
    transform: rotate(0deg);
    transition: 200ms;
}

.base header img.open {
    transform: rotate(180deg);
}
.base h1 {
    font-size: 1rem;
    margin: 10px 0;
}

.base h1 span {
    font-weight: normal;
}

.base .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
}
