.base {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
    position: sticky;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
}