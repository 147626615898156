/* IMPORTS for Noa LT Pro font ----- */
/* @font-face {
  font-family: Noa LT Pro;
  src: url(assets/font/noa-lt-pro-bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: Noa LT Pro;
  src: url(assets/font/noa-lt-pro-light.otf);
  font-weight: lighter;
}

@font-face {
  font-family: Noa LT Pro;
  src: url(assets/font/noa-lt-pro-regular.otf);
  font-weight: normal;
} */



@font-face {
  font-family: "Open Sans";
  src: url(assets/font/OpenSans-Bold.ttf);
  font-weight: bold;
}


@font-face {
  font-family: "Open Sans";
  src: url(assets/font/OpenSans-Light.ttf);
  font-weight: lighter;
}

@font-face {
  font-family: "Open Sans";
  src: url(assets/font/OpenSans-Regular.ttf);
  font-weight: regular;
}


/* ----- ----- ----- ----- ----- */


body {
  margin: 0;
  font-family: "Open Sans", sans-serif, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h4{
  margin-bottom: 12px;
  color: red;
  font-size: 1em;
}