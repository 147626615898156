.base.disabled label.checkbox span {
    background-color: #1f2d6a0d;
    border-color: #ccc;
}

.base.disabled label.checkbox span img {
   display: none;
}

.base label {
    color: #646478;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    font-weight: bold;
}

.base label.checkbox {
    color: #303030;
    flex-direction: row;
    align-items: center;
    margin: 24px 0;
}

.base label.checkbox input{
    display: none;
}

.base label.checkbox span {
    border: 1px solid #bebed28f;
    border-radius: 5px;
    background-color: white;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.base label.checkbox:hover span {
    outline: none;
    border: 1px solid #202F6C;
    box-shadow: 0 0 3px #202f6c20;
}


.base label.checkbox input:checked + span {
    background-color: #4D7CFE;
}

.base label input[type="text"], 
.base label input[type="email"] {
    font-family: "Noa LT Pro", sans-serif;
    font-size: 0.8rem;
    padding: 8px;
    border: 1px solid #bebed28f;
    border-radius: 6px;
    margin: 4px 0;
}

.base label input[type="text"]:focus, 
.base label input[type="email"]:focus,
.base label input[type="text"]:hover, 
.base label input[type="email"]:hover {
    outline: none;
    border: 1px solid #202F6C;
    box-shadow: 0 0 3px #202f6c20;
}

.base label input[type="text"]::placeholder, 
.base label input[type="email"]::placeholder {
    color: #6464788c;
}

.base button[type="submit"] {
    border: none;
    font-family: "Noa LT Pro", sans-serif;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    background-color: #1f2d6a;
}

.base button[type="submit"]:disabled {
    cursor: default;
    background-color: #BEBED2;
}

.base button[type="submit"] img {
    margin-left: 12px;
}

.base button[type="submit"].submitted {
    background-color: #517828;
}
.base button[type="submit"].error {
    background-color: #ba4646;
}

@keyframes pulse {
0% {
    transform: scale(0.75);
    opacity: 1;
}
50% {
    transform: scale(1);
    opacity: 0.2;
}
100% {
    transform: scale(0.75);
    opacity: 1;
}
}

.base .loader {
    animation: pulse  ease 2.2s infinite;
    
}
