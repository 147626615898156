.base {
  border-bottom: 80px solid #1F2D6A;
}

.base .headerImg {
  margin: 0 auto;
  max-width: 800px; 
}

.base .headerImg img {
  width: 100%;
}

.base h1 {
  font-size: 1.15rem;
  color: #1F2D6A;
  margin-bottom: 20px;
}

.base h3 {
  color: #1F2D6A;
  margin-bottom: 12px;
}
.base .container {
  margin: 40px 20px;
  max-width: 1000px;  
}

.base .container.qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 800px) {
  .base .container {
    margin: 40px auto;
    max-width: 800px;  
  }  
}

.base video {
  width: 100%;
}

.base p.mt-1 {
  margin-top: 18px;
}

.base p.mt-2 {
  margin-top: 28px;
}

/* Button styles */
.base button {
  cursor: pointer;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #1F2D6A;
  border-radius: 5px;
  transition: 200ms;
}

.base button:hover {
  background-color: #1f2d6a0d;
}

.base button:first-of-type img {
  max-height: 65%;
  object-fit: contain;
}
.base button:last-of-type img {
  max-height: 85%;
  object-fit: contain;
}